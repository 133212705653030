.App {
  text-align: center;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent; /* Light background */
  border-radius: 4px; /* Rounded corners */
}

/* Handle (the draggable scrolling element) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24); /* Darker color for contrast */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5); /* Slightly darker on hover for feedback */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: inherit !important;
}

@keyframes fadeOut {
  0% {
    background-color: rgb(159, 201, 255);
  }
  100% {
    background-color: #ffffff;
  }
}

.fade-out {
  animation: fadeOut 5s ease-in-out;
}

.thumbnail-size canvas {
  width: 100% !important; /* Override any default styles */
  height: auto !important; /* Maintain aspect ratio */
  aspect-ratio: 1.3;
  scale: 1;
  object-fit: cover;
  object-position: top;
}

.thumbnail-size-page canvas {
  width: 100% !important; /* Override any default styles */
  height: auto !important; /* Maintain aspect ratio */
  aspect-ratio: 1/1.414;
  object-fit: cover;
  object-position: top;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-collapse .ant-collapse-header {
  color: #d1d5db !important; /* Ensure this overrides existing styles */
}

.custom-collapse .ant-collapse-header:hover {
  color: #d1d5db !important; /* Ensure hover state color is applied */
}

/* Optional: ensure the content background is transparent if needed */
.custom-collapse .ant-collapse-content {
  background: transparent !important;
}

.custom-collapse .ant-collapse-content-box {
  background: transparent !important;
}
